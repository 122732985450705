.Home {
  font-style: normal;
  color: black;
  text-align: center;
  padding: 10px 10px;
}

.row {
  size: 100%;
  justify-content: center;
}

.col-header {
  font-family: Mondial Plus Demi;
  font-size: 50px;
  font-weight: bold;
  color: black;
}

.logged-in {
  font-family: Adobe Garamond;
  font-size: 40px;
  color: #a5acaf;
  font-style: italic;
}

.container {
  font-family: Adobe Garamond;
  text-align: center;
  height: 200px;
  width: 500px;
  padding-top: 40px;
}

.span-text-size {
  font-size: 75%;
}

.buttonPrintRequest {
  display: block;
  text-align: center;
  color: #f57920;
  background-color: black;
  border: 1px solid black;
  justify-content: center;
  position: relative;
  margin: 20px;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  transition-duration: 0.4s;
  width: 430px;
}

.buttonPrintRequest:hover {
  background-color: #f57920;
  color: black;
}

.buttonCurrentRequests {
  display: block;
  text-align: center;
  color: black;
  background-color: #f57920;
  justify-content: center;
  position: relative;
  margin: 20px;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  transition-duration: 0.4s;
  width: 430px;
}

.buttonCurrentRequests:hover {
  background-color: black;
  color: #f57920;
}

.buttonViewQueue {
  display: block;
  text-align: center;
  color: black;
  background-color: #f57920;
  border: 1px solid #f57920;
  justify-content: center;
  position: relative;
  margin: 20px;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  transition-duration: 0.4s;
  width: 430px;
}

.buttonViewQueue:hover {
  background-color: black;
  color: #f57920;
}

.buttonViewInkConfig {
  display: block;
  text-align: center;
  color: #f57920;
  background-color: black;
  border: 1px solid black;
  justify-content: center;
  position: relative;
  margin: 20px;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  transition-duration: 0.4s;
  width: 430px;
}

.buttonViewInkConfig:hover {
  background-color: #f57920;
  color: black;
}
