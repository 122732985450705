.update-btn {
  top: 39%;
  position: relative;
}
.mr-2, .mx-2 {
  margin-right: .5rem !important;
}
.rounded {
  border-radius: .25rem !important;
}
.mr-auto {
  margin-right: auto !important;
}

.toast-box {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 100%;
}