.input-container {
  width: 50%;
  padding: 0rem 1rem;
}

.input-container-full {
  width: 100%;
  padding: 0rem 1rem;
}

.single-input {
  width: 100%;
}

.input {
  max-width: 100% !important;
}

.input-file-uplaod {
  width: 32%;
  position: absolute;
  left: 0.75rem;
}

.input-email-body {
  height: 10rem !important;
  max-width: 100% !important;
}

.modal-dialog {
  max-width: 67%;
}

.input-lable {
  margin-bottom: 0 !important;
}

.input-group {
  margin: 0.5rem 0;
}

.table-btn {
  background: black;
  margin: 1rem 0.5rem;
}

.download-img-btn {
  background-color: #ffd400;
  border-color: #ffd400;
}

.download-img-btn:hover {
  background-color: #efc600;
  border-color: #efc600;
}
.upload-btn {
  background: #fcc603;
}
