.About {
  text-align: center;
  padding: 30px 40px;
  color: black;
  padding: 40px;
  font-family: Arial;
  font-size: 130%;
}
.mailtag {
  text-align: center;
  padding: 30px 40px;
  color: black;
  padding: 40px;
  font-family: Arial;
  font-size: 110%;
  font-style: italic;
}
.horizontal-logo {
  max-width: 798px;
  width: 50%;
  height: auto;
}
