.NavBar {
  background-color: #f57920;
  font-family: Mondial Plus Demi;
  min-height: 10vh;
  font-size: calc(10px + 2vmin);
}
.link {
  padding-right: 2em;
  color: #ffffff;
}
.link:hover {
  color: black;
  text-decoration: none;
}
.button-link {
  background-color: black;
  border: 1px solid white;
  color: #fff;
  font-size: calc(10px + 2vmin);
  width: 150px;
}
