.align-radio-btn {
	text-align: left;
	margin-left: 1.25rem;
}

.spinner-border {
  position: relative;
  left: 5.5rem;
  top: .2rem;
}

.spinner-container {
	position: absolute;
}

.toast-box {
	top: 6rem !important;
}